import React from 'react'
import NewfAboutLink from './NewfAboutLink'

const NewfAboutsRow = ({ newfabouts }) =>(
  <div className="columns is-multiline">
  {newfabouts.map((newfabout, i) =>
    <div className="column is-4" key={i}>
      <NewfAboutLink newfabout={newfabout} key={i} />
    </div>
  )}
  </div>
)

export default NewfAboutsRow
