import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import Seo from '../components/Seo'
import NewfAboutsRow from '../components/NewfAboutsRow'


const DeNewfoundlander = ({ data }) => {
  const newfabouts = data.newfabouts.edges

  return (
    <Layout pageData={data.strapiNewfoundlander}>
      <Seo title={data.strapiNewfoundlander.title} />
      <div className="container">
        <NewfAboutsRow newfabouts={newfabouts} />
      </div> 
    </Layout>
  )
}

export default DeNewfoundlander

export const query = graphql`
  query {
    strapiNewfoundlander {
      title
      subtitle
      cover {
        localFile {
          childImageSharp {
            gatsbyImageData(
              transformOptions: {grayscale: true}
            )
          }
        }
      }
    }
    newfabouts: allStrapiNewfAbout (
      sort: { order: DESC, fields: created_at }
    ) {
      edges {
        node {
          slug
          title
          content
        }
      }
    }
  }
`