import React from 'react'
import truncate from 'truncate-html'
import Markdown from 'react-markdown'

import { Link } from 'gatsby'

const NewfAboutLink = ({ newfabout }) => {

  return (
    <div className="box">
      <div className="columns ">
        <div className="column">
          <div className="content">
            <h3 className="title is-3">{newfabout.node.title}</h3>
            {newfabout.node.content &&
              <Markdown
                className="mb-3"
                children={
                  truncate(
                    newfabout.node.content, 16, { byWords: true }
                  )
                }
              />
            }
            <Link
              to={newfabout.node.slug}
              className="button is-info"
            >Lees verder</Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default NewfAboutLink
